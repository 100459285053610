import React from "react"
import Layout from '../components/layout'
import '../styles/health_packages.css'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from "react-bootstrap"

const BlogPage = (probs) => {
  const data = useStaticQuery(graphql`
    query {
        allContentfulHealthPackages {
          edges {
            node {
              slug
              place 
              price 
              nights
              days
              transportationModes
              featuredImage {
                fluid {
                  src
                }
              }
              content {
                json
              }
            }
          }
        }
      }

    `)

  return (
    <Layout>
      <div className="p_first_section">
        <div className="p_background_img">
          <div className="cover-img-overlay">
            <Container>
              <Row>

                <Col xl={8} lg={10} md={12} sm={12} xs={12}>
                  <div className="p_vertical_line"></div>
                  <h1 className="p_head_blog">Health Packages</h1>
                  <p className="p_blog_caption">We offer customized packages as well to suit the requirements<br></br>of each of our clients.</p>
                </Col>
              </Row>
            </Container>

          </div>
        </div>
        {/* <div class="p_btm_svg"></div> */}
      </div>

      <Container>
        <Row>
          {data.allContentfulHealthPackages.edges.map(edge => {
            return (
              <Col xl={4} lg={4} md={6} sm={12} xs={12} className="p_cols">
                <Link to={`/packages/${edge.node.slug}/`}>
                  <div className="pack_overlay rounded mx-auto">
                    <Img style={{ width: "100%", height: "300px", zIndex: "-1" }} fluid={edge.node.featuredImage.fluid} className="rounded mx-auto d-block" />
                    <p className="banner">{edge.node.transportationModes}</p>
                    <div className="packages-dtls-body">
                      <h5 className="place">{edge.node.place}</h5>

                    </div>
                    <div class="package_dtls">
                      <img src="../../baseline-local_airport-24px.svg" alt="icon" className="pckg_icon"></img>
                      <p style={{ marginLeft: "10px", marginBottom: '0' }}>{edge.node.nights} nights / {edge.node.days} days</p>
                    </div>
                    <p class="price">rs. {edge.node.price}</p>
                  </div>
                </Link>
              </Col>

            )
          })}






        </Row>



      </Container>
    </Layout>
  )
}
export default BlogPage;

